.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a.navbar-brand {
  background: #fff;
  padding: 10px;
  border-radius: 7px;
}
.all_categoryss ul li {
  list-style: none;
  /* background: red; */
}
.product-description ul {
  padding: 0px;
}
.product-description h4 {
  font-size: 20px;
  font-weight: normal;
  border-bottom: 1px solid #d3d3d380;
}
.product-description ul li a {
  background: #175ea2;
  padding: 11px;
  margin: 10px;
  border-radius: 21px;
  display: inline-block;
}

.product-description ul li {
  display: contents;
  /* background: red; */
}
.all_categoryss {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin: 75px 0px;
  padding: 20px;
}


.product-image {
  border: 1px solid lightgray;
  margin: 74px 0px;
  overflow: hidden;
  text-align: center;
}
div#sidebar ul li {
  list-style: none;
}
div#sidebar {
  position: fixed;
  top: 237px;
  right: 4px;
  z-index: 9999999;
}
.product-description {
  margin: 74px 0px;
}
.product-description h4 b {
  font-weight: 800;
}

.product-description {
  margin: 74px 0px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ben {

  background-position: center;
  padding: 89px 0px;
}
.benefit-content h4, .benefit-content h2, .benefit-content p, .benefit-content ul li {
  color: #fff;
}
.benefit-content ul li {
  font-size: 17px;
}
.benefit-one {
  overflow: hidden;
}
.all_category h4 {
  color: #fff;
  padding: 8px 10px;
}
.all_category ul {
  padding: 0px;
}
.all_category li:hover, .all_category li:hover a {
  background: #fff;
  color: #0457a7;
  font-weight: bold;
}
.all_category ul li {
  padding: 11px;
  list-style: none;
  border-bottom: 1px solid #fff;
}
.all_category {
  background: #0457a7;
  margin: 20px;
  /* padding: 20px; */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.product-image img {
  width: 100%;
}




